<template>
  <div>
    <Menu />
    <div id="teacherDetails" v-if="teacherContent">
      <div class="top diyImg">
        <div class="content d-flex d-flex-top">
          <div class="topL">
            <div class="td_t_img">
              <div class="diyImg" :style="{'background-image': 'url('+$store.state.httpUrl + teacherContent.teacher_HeadImgPath+')'}"></div>
            </div>
            <p class="td_t_name">{{teacherContent.teacher_RealName}}</p>
            <p class="td_t_label">{{teacherContent.industry_Title}}</p>
          </div>
          <div class="topR">
            <p class="td_t_title">任教年龄</p>
            <p class="td_t_txt"> {{teacherContent.teacher_Experience}}</p>
            <p class="td_t_title">主讲课程</p>
            <p class="td_t_txt">{{teacherContent.teacher_DoMain}}</p>
            <div class="td_t_decs">
              <img src="../assets/images/marksleft.png" />
              <img src="../assets/images/marksright.png" />
              <p class="txtOver4">{{teacherContent.teacher_Indroce}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- crumbs -->
      <div class="crumbs">
        <div class="content d-flex d-flex-middle">
          <p class="color9 fontSize12">当前所在位置：</p>
          <router-link to="/" class="color9 fontSize12">首页</router-link>
          <p class="color9 fontSize12 and">></p>
          <p class="color9 fontSize12">名师堂</p>
          <p class="color9 fontSize12 and">></p>
          <p class="color9 fontSize12">{{teacherContent.industry_Title}}</p>
          <p class="color9 fontSize12 and">></p>
          <p class="color fontSize12">{{teacherContent.teacher_RealName}}</p>
        </div>
      </div>
      <!-- 相关课程 -->
      <div class="related">
        <div class="content bg_fff">
          <p class="td_title">TA的相关课程</p>
          <div class="relatedContent d-flex d-flex-top d-flex-between" v-if="listVideo.length>0">
            <div class="qcode-moadal video">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions" @timeupdate="onPlayerTimeupdate($event)">
              </video-player>
              <div class="qc-cnt" v-if="qrcodShow">
                <div class="inner">
                  <img src="../assets/images/qrcod.png" alt="">
                  <div class="txt">扫码进入微信小程序</div>
                  <div class="txt">领取更多专属课程</div>
                </div>
              </div>
            </div>
            <div class="videoList">
              <div class="videoList-group">
                <div class="videoLi_title">课程目录</div>
                <div class="videoLi_li d-flex d-flex-between d-flex-middle" v-for="(item,index) in listVideo" :key="index" :class="{active:tabVideoActive==index}">
                  <p class="txtOver" @click="changeVideo(item,index)">{{item.video_Title}}</p>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="none" v-else>
            <img src="../assets/images/none.png"/>
          </div>
        </div>
      </div>
      <!-- 名师推荐 -->
      <div class="recommend">
        <div class="content bg_fff">
          <p class="td_title">名师推荐</p>
          <div class="recommend_list">

            <!-- <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">

                </div>
              </div>
           
              <div class="swiper-pagination"></div>
            </div> -->

            <swiper class="swiper-wrapper" :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item,index) in corTeacherList" :key="index" class="swiper-slide">
                <router-link :to="'/TeacherDetail/' + item.teacher_Id" class="rLi">
                  <div class="diyImg" :style="{'background-image': 'url('+$store.state.httpUrl + item.teacher_HeadImgPath+')'}"></div>
                  <div class="d-flex d-flex-middle d-flex-between">
                    <p>{{item.teacher_RealName}}</p>
                    <p class="txtOver">{{item.teacher_Experience}}</p>
                  </div>
                </router-link>
              </swiper-slide>
            </swiper>

          </div>
        </div>
      </div>
    </div>
    <div class="none" v-else>
      <img src="../assets/images/none.png"/>
    </div>
    <Foot />
    <RightGuide />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { teacherById, tjTeacher } from "@/api/app"
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Menu, Foot, RightGuide, videoPlayer, swiper, swiperSlide
  },
  data() {
    return {
      tabVideoActive: 0,
      listVideo: [],
      teacherContent: '',
      qrcodShow: false,
      teacherId: '',
      playsinline: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'application/x-mpegURL', // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      gklog: '',    //观看时长
      currentUrl: '', //当前播放的视频地址
      videoItem: '', //当前播放视频的item

      corTeacherList: [],
      swiperOption: { //轮播配置
        notNextTick: true,
        slidesPerView: 4,
        paginationClickable: true,
        // autoplay: {
        //   delay: 20000,  //这里需要注意，如果想每2秒去自动切换，直接autoplay:2000是失效的，
        //   disableOnInteraction: false,
        // },
        autoplay: 2000,
        loop: false,
        on: {
          click: function (e) {
            let realIndex = this.realIndex;
            //  console.log(e,'轮播')
            vm.jumpDesc(realIndex)  //这里调用的是定义在methods里面的方法
          }
        }
      }
    }
  },
  mounted() {
    this.teacherId = this.$route.params.id;
    this.getTeacherById()
    this.getTjTeacher()
  },

    watch: {
    $route(to, from) {
      // x详情页路由不刷新 判断跳转的路径是否你要监听的路径，更新数据
      if (to.path.includes('/TeacherDetail')) {
        this.teacherId = to.params.id
    this.getTeacherById()
    this.getTjTeacher()
      }
    }
  },

  methods: {

    /* 获取视频播放进度 */
    onPlayerTimeupdate(player) {
      this.gklog = player.cache_.currentTime
      //  console.log(' onPlayerTimeupdate!', this.gklog)

      //超出观看时长
      if (this.gklog > this.videoItem.video_Secound) {
        //  this.$refs.videoPlayer.player.pause()
        //  this.playerOptions['sources'][0]['src'] = 'https://video-convert.oss-cn-hangzhou.aliyuncs.com/videom3u8/76GdQhpFbN/76GdQhpFbN.m3u8'
        this.$refs.videoPlayer.player.src(this.currentUrl)
      }
    },

    //切换视频
    changeVideo(item, index) {
      this.currentUrl = this.$store.state.videoUrl + item.video_Path
      this.videoItem = item
      this.$refs.videoPlayer.player.src(this.currentUrl)
      this.qrcodShow = false
      this.tabVideoActive = index
    },


    //教师详情
    getTeacherById() {
      const params = {
        teacher_Id: this.teacherId,
      }
      teacherById(params).then((res) => {
        if (res.status) {
          this.teacherContent = res.data.teacher
          this.listVideo = res.data.listVideo
          if(res.data.listVideo.length > 0){
            //请求完接口，播放第一个item的视频
            this.currentUrl = this.$store.state.videoUrl + this.listVideo[0].video_Path
            this.playerOptions['sources'][0]['src'] = this.currentUrl
            this.videoItem = this.listVideo[0]
          }
        }
      })
    },

    //推荐教师
    getTjTeacher() {
      tjTeacher().then((res) => {
        if (res.status) {
          this.corTeacherList = res.data.listTeacher
          console.log(this.corTeacherList)
        }
      })
    },

  }
}
</script>

<style lang="scss">
#teacherDetails {
  /*  */
  body {
    background-color: #fafafa;
  }
  .top {
    height: 422px;
    background-image: url(../assets/images/teachertopbg.png);
  }
  .top .content {
    box-sizing: border-box;
    padding-top: 40px;
  }
  .topL {
    text-align: center;
    margin-right: 110px;
  }
  .topL .td_t_img {
    width: 210px;
    height: 210px;
    border: 2px solid #8ac2f8;
    box-sizing: border-box;
    padding: 5px;
  }
  .topL .td_t_img .diyImg {
    width: 100%;
    height: 100%;
  }
  .td_t_name {
    line-height: 70px;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
  }
  .td_t_label {
    display: inline-block;
    padding: 0 10px;
    min-width: 90px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    line-height: 30px;
    color: #0087ed;
    font-size: 12px;
    margin: 0 auto;
  }
  .topR {
    flex: 1;
  }
  .td_t_title {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }
  .td_t_txt {
    margin: 10px 0;
    color: #8ac2f8;
    font-size: 14px;
    line-height: 20px;
  }
  .td_t_decs {
    position: relative;
    margin-top: 55px;
  }
  .td_t_decs img {
    position: absolute;
    height: 30px;
    width: 30px;
  }
  .td_t_decs img:nth-of-type(1) {
    left: -50px;
    top: -20px;
  }
  .td_t_decs img:nth-of-type(2) {
    right: -40px;
    bottom: -20px;
  }
  .td_t_decs p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    line-height: 30px;
    height: 120px;
  }
  /*  */
  .td_title {
    height: 66px;
    line-height: 66px;
    position: relative;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    padding-left: 56px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .td_title::before {
    content: "";
    position: absolute;
    left: 40px;
    top: 23px;
    height: 20px;
    width: 4px;
    background-color: #0091ff;
  }
  /*  */
  .related {
    margin-bottom: 50px;
  }
  .related .content {
    border-radius: 10px;
  }
  .relatedContent {
    box-sizing: border-box;
    padding: 30px 40px;
  }
  .relatedContent .video {
    width: 900px;
    height: 500px;
  }
  .videoList {
    flex: 1;
    margin-left: 20px;
    height: 500px;

    border: none;

    .videoList-group {
      height: 100%;
      width: 100%;
      overflow: auto;
      float: left;
      margin: 5px;
      border: none;
      overflow: auto;
    }
    // .scrollbar {
    // width : 30px;
    // height: 300px;
    // margin: 0 auto;
    // }
    .videoList-group::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    .videoList-group::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(150, 149, 149, 0.2);
      background: #adadad;
    }
    .videoList-group::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(207, 204, 204, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
  }
  .videoLi_title {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    padding-left: 30px;
    background: url(../assets/images/down_icon_3.png) left center no-repeat;
    background-size: 12px;
    cursor: pointer;
  }
  .videoLi_list {
    display: none;
  }
  .videoLi_li {
    height: 36px;
    cursor: pointer;
  }
  .videoLi_li p:nth-of-type(1) {
    padding-left: 30px;
    background: url(../assets/images/locked.png) left center no-repeat;
    background-size: 16px;
    flex: 1;
  }
  .videoLi_li p:nth-of-type(2) {
    width: 40px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    background: #0091ff;
    border-radius: 5px;
    text-align: center;
    margin-left: 5px;
  }
  .videoLi_li.active p:nth-of-type(1) {
    color: #0091ff;
  }
  .videoLi_li.can {
    background: url(../assets/images/unlocking.png) left center no-repeat;
    background-size: 16px;
  }
  .on .videoLi_list {
    display: block;
  }
  /*  */
  .recommend {
    margin-bottom: 100px;
  }
  .recommend .content {
    border-radius: 10px;
  }
  .recommend_list {
    box-sizing: border-box;
    padding: 30px;
  }
  .recommend_list .swiper-container {
    width: 100%;
    height: 100%;
  }
  .rLi {
    display: block;
    width: 200px;
    margin: 0 auto;
  }
  .rLi .diyImg {
    width: 198px;
    height: 198px;
    // background-image: url(../assets/images/teacherpic.png);
    margin: 0 auto 15px auto;
  }
  .rLi .diyImg + div p:nth-of-type(1) {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  .rLi .diyImg + div p:nth-of-type(2) {
    font-size: 12px;
    color: #0087ed;
    max-width: 60px;
  }

  .vjs-progress-control {
    pointer-events: none !important;
  }

  .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin: -40px;
  }

  .qcode-moadal {
    position: relative;
    .qc-cnt {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);

      .inner {
        width: 200px;
        margin: 90px auto 0;
        text-align: center;
        background: #fff;
        border-radius: 30px;
        padding: 40px 80px;
        position: relative;
        .txt {
          line-height: 20px;
        }
      }
      
    }
    .video-player{
      height: 100%;
      .video-js.vjs-fluid{
        height: 100%;
      }
    }
  }

}
</style>